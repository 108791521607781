import { Row, Col, Button } from "react-bootstrap"
import MeasurementLocationCard from "./MeasurementLocationCard"
import MeasurementTimber from "./MeasurementDetails"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import MeasurementLogsTable from "./MeasurementLogsTable"
import { measurementsStateFamily } from "common/recoil/atoms"
import { Suspense, useEffect } from "react"
import useFetch from "common/hooks/useFetch"
import Loading from "common/other/Loading"
import MeasurementDevice from "common/other/MeasurementDevice"
import { Trans, t } from "@lingui/macro"
import useExportsState from "exports/useExportsState"
import normalizeJson from "common/utils/normalizeJson"
import MeasurementVolumeTable from "./MeasurementVolumeTable"
import MeasurementCullsTable from "./MeasurementCullsTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { MetrologyCard } from "public-measurement"
import MeasurementEditorCard from "common/other/MeasurementEditorCard"
import MeasurementCustomFieldsCard from "custom_fields/MeasurementCustomFieldsCard"
import { currentOrganizationState } from "common/navigation/OrganizationSelector"
import ShareMeasurementModal from "./ShareMeasurementModal"
import MeasurementMetadata from "./MeasurementMetadata"

export default function MeasurementSingleView() {
  const navigate = useNavigate()
  const organization = useRecoilValue(currentOrganizationState)
  const { id: measurementId } = useParams()
  const [measurement, setMeasurement] = useRecoilState(
    measurementsStateFamily(Number(measurementId))
  )
  const fetch = useFetch()
  const { createMeasurementPdf } = useExportsState()
  useEffect(() => {
    if (!measurement) {
      fetch(
        `/measurements/${measurementId}?${new URLSearchParams({
          fields:
            "storage_id,weight,weight_density,photo,photo_optimized,latitude,longitude,photo_thumbnail,volume,coefficient,wood_type_id,log_length,shipment_number,measurement_type_id,wood_quality_id,assortment_id,volume_formula,arrival_date,photo_file_name,load_lines,measurement_area,device_id,poor_detection,comment",
          extra_fields:
            "logs,references,load_json,custom_fields,logs_sheet,comment"
        })}`
      ).then(response => {
        setMeasurement(normalizeJson(response.data))
      })
    }
    // eslint-disable-next-line
  }, [])
  const uniqueAssortmentIds = [
    ...new Set(measurement?.logs.map($ => $.wood_characteristic_id))
  ]
  const uniqueCullIds = [
    ...new Set(measurement?.logs.filter($ => $.cull_id).map($ => $.cull_id))
  ]

  return measurement ? (
    <Suspense fallback={<Loading text={t`Loading measurement...`} />}>
      <Row className="mb-3">
        <Col>
          <h5 className="text-light mb-0 d-flex align-items-center flex-wrap gap-3">
            <Button size="sm" variant="light" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </Button>
            <span>{measurement?.photo_file_name || "NO_PHOTO_ERROR"}</span>
            <ShareMeasurementModal
              measurement={measurement}
              organization={organization}
            />
            <Button
              onClick={() => createMeasurementPdf(measurement.id)}
              size="sm"
              variant="light"
            >
              <Trans>Export to PDF</Trans>
            </Button>
          </h5>
        </Col>
      </Row>
      {/* <Row className="mb-3">
        <Col className="text-white-50">
          <b className="me-1">ID:</b>
          <span>{measurement.id}</span>
        </Col>
      </Row> */}
      <Row className="gy-4">
        <Col xs="12" lg="6" xxl="4" className="d-flex flex-column gap-4">
          <MeasurementTimber measurement={measurement} />
          <MeasurementMetadata measurement={measurement} />
        </Col>
        <Col xs="12" lg="6" xxl="4" className="d-flex flex-column gap-4">
          <MeasurementEditorCard measurement={measurement} type="timber" />
          <MeasurementLocationCard measurement={measurement} />
          <MeasurementDevice deviceId={measurement.device_id} />
        </Col>
        {measurement?.metrology !== null && (
          <Col xs="12" lg="6" xxl="4">
            <MetrologyCard metrology={measurement.metrology} />
          </Col>
        )}
        <Col xs="12" lg="6" xxl="4" className="d-flex flex-column gap-4">
          <MeasurementCustomFieldsCard measurement={measurement} />
        </Col>
        {measurement.logs?.length !== 0 && (
          <Col xs="12" lg="6">
            <MeasurementLogsTable measurement={measurement} />
          </Col>
        )}
        {uniqueAssortmentIds?.length > 1 && (
          <Col xs="12" lg="6">
            <MeasurementVolumeTable
              logs={measurement.logs}
              measurementId={measurement.id}
            />
          </Col>
        )}
        {uniqueCullIds?.length > 0 && (
          <Col xs="12" lg="6">
            <MeasurementCullsTable logs={measurement.logs} />
          </Col>
        )}
      </Row>
    </Suspense>
  ) : (
    <Loading />
  )
}
