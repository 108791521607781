import { Form, Card, Image } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import { useSetRecoilState } from "recoil"
import { measurementEditorState } from "timber-measurement/editor/useEditorState"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import useVolumeFormatter from "common/hooks/useVolumeFormatter"

export default function TruckMeasurementEditor({ measurement }) {
  const measurementEditor = useSetRecoilState(measurementEditorState)
  const formatVolume = useVolumeFormatter()

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Measurement editor</Trans>
      </Card.Header>
      <Form.Group className="mb-3">
        <Image
          fluid
          src={measurement.image}
          onError={e => (e.target.src = "/android-chrome-192x192.png")}
          onClick={() => {
            window.history.pushState("forward", null, "#editor")
            measurementEditor({ id: measurement.id, type: "truck" })
          }}
          className="me-2 hover-dark border-bottom"
          style={{
            cursor: "pointer"
          }}
        />
        <div className="pt-3 text-center">
          <Trans>Volume</Trans>: {formatVolume(measurement.volume)}
        </div>
      </Form.Group>
    </Card>
  )
}
