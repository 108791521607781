import { useState } from "react"
import { Form, Button, Card, InputGroup } from "react-bootstrap"
import Select from "common/other/Select"
import useFetch from "common/hooks/useFetch"
import measurementTypes from "common/enums/measurementTypes"
import { Trans } from "@lingui/macro"
import { useSetRecoilState } from "recoil"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { truckMeasurementsStateFamily } from "common/recoil/atoms"

export default function MeasurementTimber({ measurement }) {
  const [measurementType, setMeasurementType] = useState(
    measurement.measurement_type_id || ""
  )
  const [weight, setWeight] = useState(measurement.weight || "")
  const [weightDensity, setWeightDensity] = useState(
    measurement.weight_density || ""
  )
  const setMeasurement = useSetRecoilState(
    truckMeasurementsStateFamily(measurement.id)
  )
  const fetch = useFetch()

  function updateMeasurement(event) {
    event.preventDefault()

    fetch(`/truck_measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        truck_measurement: {
          measurement_type_id: Number(measurementType) ?? undefined,
          weight: weight || undefined,
          weight_density: Number(weightDensity) || undefined
        }
      }
    })
      .then(response => {
        if (response) {
          toast.success(<Trans>Your changes have been saved</Trans>)
          setMeasurement(response)
        }
      })
      .catch(() => toast.error(<Trans>Failed to save changes</Trans>))
  }

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faEdit} className="me-2" />
        <Trans>Measurement details</Trans>
      </Card.Header>
      <Card.Body>
        <Form
          id="update-truck-measurement-details"
          onSubmit={updateMeasurement}
        >
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Measurement type</Trans>
            </Form.Label>
            <Select
              onChange={event => setMeasurementType(event.value)}
              value={{
                value: measurementType,
                label: measurementTypes[measurementType]
              }}
              options={Object.entries(measurementTypes).map(([id, type]) => ({
                value: id,
                label: type
              }))}
            />
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Weight</Trans>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                onChange={event => setWeight(event.target.value)}
                className="form-control-append"
                value={weight}
              />
              <InputGroup.Text>kg</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Weight density</Trans>
            </Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                onChange={event => setWeightDensity(event.target.value)}
                className="form-control-append"
                value={weightDensity}
              />
              <InputGroup.Text>m³/t</InputGroup.Text>
            </InputGroup>
          </Form.Group>
          {/* <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Weight per log</Trans>
            </Form.Label>
            <Form.Control readOnly disabled defaultValue={0} />
          </Form.Group> */}
        </Form>
        <Button
          className="w-full mt-3"
          type="submit"
          form="update-truck-measurement-details"
        >
          <Trans>Save</Trans>
        </Button>
      </Card.Body>
    </Card>
  )
}
