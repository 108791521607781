import { useEffect } from "react"
import Pusher from "pusher-js"
import { useSetRecoilState } from "recoil"
import { reportsState } from "common/recoil/atoms"
import { Trans } from "@lingui/macro"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"

const pusherAppKey =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_ENV === "staging"
    ? "e67995f91093b97e0841"
    : process.env.NODE_ENV === "production"
    ? "f33f23d9d0260c105bd2"
    : ""
const pusher = new Pusher(pusherAppKey, {
  cluster: "eu"
})
const channel = pusher.subscribe("report-channel")

export default function ReportsNotifications() {
  const setReports = useSetRecoilState(reportsState)

  function reportCompleted(data) {
    // console.log(data)
    setReports(reports => {
      toast(
        <>
          <div>
            <Trans>Report {data.report.name} is now ready.</Trans>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="me-2 text-success"
            />
            <Trans>
              {data?.report?.added_measurements} measurements were added to the
              report.
            </Trans>
          </div>
          {data?.report?.not_added_measurements !== 0 && (
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="me-2 text-danger"
              />
              <Trans>
                {data?.report?.not_added_measurements} measurements could not be
                added to the report.
              </Trans>
            </div>
          )}
          <div>
            <Trans>Click here to close this notification.</Trans>
          </div>
        </>,
        { autoClose: false }
      )
      if (reports.find($ => $.id === data.report.id)) {
        return reports.map(report =>
          report.id === data.report.id
            ? {
                ...report,
                ...data.report
              }
            : report
        )
      }

      return [data.report, ...reports]
    })
    // const pdfExports = getPdfReports()
    // if (data?.pdf_export.user_id !== account.id) return
    // toast.info(
    //   <p onClick={() => navigate("/exports")}>
    //     <b>{data.pdf_export.name}</b>{" "}
    //     <Trans>has finished generating (PDF Export)</Trans>
    //   </p>,
    //   {
    //     autoClose: 15000,
    //     closeButton: true
    //   }
    // )
    // const existing =
    //   pdfExports?.length > 0
    //     ? pdfExports.find($ => $.id === data.pdf_export.id)
    //     : false
    // if (existing) {
    //   // setPdfExports($$ =>
    //   //   $$.map($ => {
    //   //     if ($.id !== data.pdf_export.id) return $
    //   //     return data.pdf_export
    //   //   })
    //   // )
    //   updatePdfReport(data.pdf_export)
    // } else {
    //   addPdfReport(data.pdf_export)
    // }
  }

  useEffect(() => {
    channel.bind("storage-report-updated", reportCompleted)

    return () => {
      channel.unbind("storage-report-updated")
    }
    // eslint-disable-next-line
  }, [])

  return <div></div>
}
