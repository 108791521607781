import { faStickyNote } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Trans } from "@lingui/macro"
import Spinner from "common/other/Spinner"
import { useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useSetRecoilState } from "recoil"
import { toast } from "react-toastify"
import useFetch from "common/hooks/useFetch"
import { containerMeasurementState } from "./ContainerMeasurementRowList"

export default function ContainerMeasurementMetadata({ measurement }) {
  const setMeasurement = useSetRecoilState(
    containerMeasurementState(measurement.id)
  )
  const [comment, setComment] = useState(measurement?.comment || "")
  const [saving, setSaving] = useState(false)
  const fetch = useFetch()

  const handleSubmit = event => {
    event.preventDefault()
    setSaving(true)
    fetch(`/containers/measurements/${measurement.id}`, {
      method: "PATCH",
      body: {
        comment: comment || undefined
      }
    })
      .then(updatedMeasurement => {
        if (updatedMeasurement) {
          setMeasurement({
            ...measurement,
            ...updatedMeasurement
          })
          toast.success(<Trans>Your changes have been saved</Trans>)
        } else {
          toast.error(<Trans>Your changes could not be saved</Trans>)
        }
      })
      .finally(() => setSaving(false))
  }

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faStickyNote} className="me-2" />
        <Trans>Measurement metadata</Trans>
      </Card.Header>
      <Card.Body>
        <Form
          id="update-container-measurement-metadata"
          onSubmit={handleSubmit}
        >
          <Form.Group>
            <Form.Label>
              <Trans>Comment</Trans>
            </Form.Label>
            <Form.Control
              style={{ height: "8rem" }}
              as="textarea"
              value={comment}
              onChange={event => setComment(event.target.value)}
            />
          </Form.Group>
          <div className={`d-grid mt-4`}>
            <Button
              form="update-container-measurement-metadata"
              type="submit"
              disabled={saving}
            >
              {saving ? (
                <Spinner
                  size="sm"
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <Trans>Save</Trans>
              )}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  )
}
