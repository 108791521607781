import { Row, Col, Button } from "react-bootstrap"
import MeasurementTimber from "./MeasurementTimber"
import { useNavigate, useParams } from "react-router-dom"
import { useRecoilState } from "recoil"
import LogisticsMap from "./logistics/LogisticsMap.js"
import { truckMeasurementsStateFamily } from "common/recoil/atoms"
import MeasurementLocationCard from "timber-measurement/MeasurementLocationCard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { t, Trans } from "@lingui/macro"
import useExportsState from "exports/useExportsState"
import { Suspense, useEffect } from "react"
import useFetch from "common/hooks/useFetch"
import Loading from "common/other/Loading"
import TruckMeasurementEditor from "./TruckMeasurementEditor"
import TruckMeasurementMetadata from "./TruckMeasurementMetadata"

export default function TruckMeasurementView() {
  const { id: measurementId } = useParams()
  const [measurement, setMeasurement] = useRecoilState(
    truckMeasurementsStateFamily(Number(measurementId))
  )
  const fetch = useFetch()
  const navigate = useNavigate()
  const { createTruckMeasurementPdfReport } = useExportsState()

  useEffect(() => {
    if (!measurement) {
      fetch("/truck_measurements/" + measurementId).then(response => {
        setMeasurement(response)
      })
    }
    // eslint-disable-next-line
  }, [])

  return measurement ? (
    <Suspense fallback={<Loading text={t`Loading measurement...`} />}>
      <Row>
        <Col xs="12" className="mb-3">
          <h5 className="text-light mb-0 d-flex align-items-center flex-wrap gap-3">
            <Button size="sm" variant="light" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </Button>
            {measurement.photo_file_name}
            <Button
              onClick={() => createTruckMeasurementPdfReport(measurement.id)}
              size="sm"
              variant="info"
            >
              <Trans>Export to PDF</Trans>
            </Button>
          </h5>
          {/* <div className="text-white-50">
            <b className="me-1">ID:</b>
            <span>{measurement.id}</span>
          </div> */}
        </Col>
      </Row>
      <div className="d-flex flex-wrap row gy-4">
        <Col xs="12" lg="6" xl="4" className="d-flex flex-column gap-4">
          <MeasurementTimber measurement={measurement} />
        </Col>
        <Col xs="12" lg="6" xl="4" className="d-flex flex-column gap-4">
          <MeasurementLocationCard measurement={measurement} />
        </Col>
        <Col xs="12" lg="6" xl="4" className="d-flex flex-column gap-4">
          <TruckMeasurementEditor measurement={measurement} />
          <TruckMeasurementMetadata measurement={measurement} />
        </Col>
        <Col xs="12" lg="6">
          <LogisticsMap measurement={measurement} />
        </Col>
      </div>
    </Suspense>
  ) : (
    <Loading />
  )
}
