import { Form, Card, InputGroup } from "react-bootstrap"
import { Trans } from "@lingui/macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStickyNote } from "@fortawesome/free-solid-svg-icons"

export default function TruckMeasurementMetadata({ measurement }) {
  return (
    <Card className="mb-4">
      <Card.Header>
        <FontAwesomeIcon icon={faStickyNote} className="me-2" />
        <Trans>Measurement metadata</Trans>
      </Card.Header>
      <Card.Body>
        <Form id="truck-measurement-metadata">
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Measured at</Trans>
            </Form.Label>
            <Form.Control
              defaultValue={new Date(measurement.measured_at).toLocaleString()}
              readOnly
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              <Trans>Log count</Trans>
            </Form.Label>
            <Form.Control
              readOnly
              disabled
              defaultValue={measurement?.logs?.length || 0}
            />
          </Form.Group>
          {/* <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Weight per log</Trans>
            </Form.Label>
            <Form.Control readOnly disabled defaultValue={0} />
          </Form.Group> */}
          <Form.Group className="mb-2">
            <Form.Label>
              <Trans>Reference size</Trans>
            </Form.Label>
            <InputGroup>
              <Form.Control
                readOnly
                disabled
                defaultValue={
                  measurement?.references?.length > 0
                    ? measurement.references[0].size
                    : null
                }
              />
              <InputGroup.Text>m</InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}
